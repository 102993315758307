const maping = {
    Home: {
        title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
        metaTags: [
            {
                name: 'keywords',
                content: '地质工程云平台，地质工程云平台GPT，地质工程云平台GPT云平台，地质工程云平台大模型，地质工程云平台GPT大模型，油气资讯GPTs，企业管理知识GPTs，油气专业类GPTs，通用类GPTs，安东人工智能技术公司，安迅数智',
            },
            {
                name: 'description',
                content: '安东地质工程云平台GPT云平台，发挥安东领先的、创新型一体化油田技术服务优势，为客户提供油气资讯GPTs、企业管理知识GPTs、油气专业类GPTs和通用类GPTs等在线订阅服务，无需投入大量的资源建设，即可享受到人工智能所带来的便利。',
            },
        ]
    },
    gptDesign: {
        title: '设计类GPTs _油气行业设计类大模型人工智能应用-地质工程云平台GPT',
        metaTags: [
            {
                name: 'keywords',
                content: '设计GPTs，设计类GPTs ，压裂设计方案参数优化，钻井工程方案设计，油气田开发设计，地质工程云平台，地质工程云平台GPT，地质工程云平台GPT云平台，地质工程云平台大模型，地质工程云平台GPT大模型',
            },
            {
                name: 'description',
                content: '地质工程云平台GPT云平台提供设计类GPTs，基于目标区域的地质条件、地层结构、工程要求、产量目标等多方面因素，制定出科学、合理、可行的设计方案，以实现油气资源的高效、安全、经济开发。',
            },
        ]
    },
    gptTranslate: {
        title: '解释类GPTs_油气行业解释类大模型人工智能应用-地质工程云平台GPT',
        metaTags: [
            {
                name: 'keywords',
                content: '解释GPTs，解释类GPTs，储层识别，岩性识别，设备智能监测，地质工程云平台，地质工程云平台GPT，地质工程云平台GPT云平台，地质工程云平台大模型，地质工程云平台GPT大模型',
            },
            {
                name: 'description',
                content: '地质工程云平台GPT云平台提供解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持。',
            },
        ]
    },
    gptTechnology: {
        title: '工艺类GPTs_油气行业工艺类大模型人工智能应用-地质工程云平台GPT',
        metaTags: [
            {
                name: 'keywords',
                content: '3.工艺GPTs，工艺类GPTs，智能地质导向，智能注采决策，油气田视频监控，钻井风险预警，人工举升优化，机采智能管控，油气田能耗优化，地质工程云平台，地质工程云平台GPT，地质工程云平台GPT云平台，地质工程云平台大模型，地质工程云平台GPT大模型',
            },
            {
                name: 'description',
                content: '地质工程云平台GPT云平台提供工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发。',
            },
        ]
    },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
    // Home: {
    //     title: '地质工程云平台GPT云平台-全球油气行业专业、开放的大模型人工智能云平台',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'home,vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是首页的一段描述',
    //         },
    //     ]
    // },
    // about: {
    //     title: '关于',
    //     metaTags: [
    //         {
    //             name: 'keywords',
    //             content: 'about, vue, router, meta',
    //         },
    //         {
    //             name: 'description',
    //             content: '这是关于的一段描述',
    //         },
    //     ]
    // },
};

export default maping;
