<template>
  <div>
    <div v-if="model === false" class="layer1">
      <div class="leftLayer" @click="back"></div>
      <div class="rightLayer">
        <div class="communicitynet" @click="English"><div class="net"></div> &nbsp;&nbsp;English</div>
        <div class="communicityGPT" @click="gpts">GPT社区</div>
        <div class="communicity" @click="show">诚邀加盟</div>
        <div v-if="userId" class="loginUser">
          <div style="display: flex">
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="40" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  Hi!<br/>
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 1vw;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                   class="hoverItem">
                  <router-link style="display: flex" class="flex" to="/userInformation">
                    <div>
                      <img
                          alt=""
                          class="icon not"
                          style="width: 20px; height: 20px; margin: 7px 10px auto 0"
                          src="@/assets/userIcon.png"
                      />
                    </div>
                    <span style="margin-top: 0;font-size: 14px;width: 80px">个人中心</span>
                    <span style="position: absolute;right:5px;margin-left: 10px;font-size: 12px"><i class="el-icon-arrow-right"></i></span>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item
                    style="display: flex; height:32px;text-align: left;font-size: 1vw;font-family: arial, sans-serif, 'Microsoft Yahei'"
                    @click.native="outLogin()"
                >
                  <img loading="lazy"
                      alt=""
                      class="icon hover"
                      src="@/assets/outHoverIcon.png"
                       style="width: 20px; height: 20px; margin: 7px 10px auto 0"

                  />

                  <span style="margin-top: 0;font-size: 14px;width: 80px">退出登录</span>
                  <span style="position: absolute;right:5px;margin-left: 10px;font-size: 12px"><i class="el-icon-arrow-right"></i></span>

                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
<!--        <div v-else class="login">登录/注册</div>-->
<!--        <div v-else class="login" @click="login">登录/注册</div>-->
      </div>
    </div>
    <div v-else>
      <div class="layerModel">
        <div class="leftLayer" @click="back"></div>

        <div class="rightLayer">
          <div v-if="userId" class="loginUser">
          <div style="display: flex">
            <div class="communicitynet" @click="English"><div class="net"></div> &nbsp;&nbsp;English</div>
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="35" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  Hi!<br/>
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 12px;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                   class="hoverItem">
                  <router-link style="display: flex" class="flex" to="/infoMobile">
                    <div>
                      <img
                          alt=""
                          class="icon not"
                          style="width: 20px; height: 20px; margin: 7px 5px auto 0"
                          src="@/assets/userIcon.png"
                      />
                    </div>
                    <span style="margin-top: 0">个人中心</span>

                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 12px;height: 32px"
                    @click.native="outLogin()"
                >
                  <img loading="lazy"
                       alt=""
                       class="icon hover"
                       src="@/assets/outHoverIcon.png"
                       style="width: 20px; height: 20px; margin: 8px 5px auto 0"
                  />
                  退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
          <div v-else style="display: flex">
            <div class="communicitynet" @click="English"><div class="net"></div> &nbsp;&nbsp;English</div>
<!--            <div  class="login" @click="login">登录/注册</div>-->
          </div>
        </div>
      </div>
    </div>

    <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
  </div>
</template>


<script>
import {
  logOut,
  userDetail,
  // getAuthorize,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";
import PartnerTip from "@/components/partnerTip.vue";

export default {
  name: "headtip",
  props: [],
  components: {PartnerTip},
  data() {
    return {
      model: false,
      userName: "",
      avator: require("@/assets/user.jpg"),
      userId: localStorage.getItem("userId"),
      userInfo:"",
      // userId:48,
      dialogconsult: false,
      baseURL: this.$utils.baseURL,
    };
  },
  created() {
    console.log("userId", this.userId);

  },
  watch: {
    "$store.state.userId"(newValue, oilValue) {
      this.userId = newValue;
      if (newValue && newValue != oilValue) {
        this.getUser();
        this.ifComplete()
      }
    },
  },
  mounted() {
    const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true;
    } else {
      this.model = false;
    }
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    console.log(options, "options");
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });
    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      this.getUser();
      this.ifComplete()
    }
  },
  methods: {
    ifComplete(){
      this.$axios
          .get("/info/gpts_user/judgment_info/" + this.userId)
          .then((res) => {
            if(res.data.msg=="信息已完善"){
              localStorage.setItem("infoComplete",1)
            }
            else{
              localStorage.setItem("infoComplete",0)
            }

            console.log("--------ren",res.data)

          })
          .catch((err) => {
            console.log(err);
          });

    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
          .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
          .then((res) => {
            console.log(res, "res11111");
            let options = this.$route.query;
            if (res.data.data.isLogin == true && this.$store.state.access_token) {
              this.getUser();
              this.ifComplete()
            } else if (
                res.data.data.isLogin == true &&
                !this.$store.state.access_token &&
                !options.code
            ) {
              this.login();
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 2
            ) {
              console.log("isLogin Flase type 2");
              //this.outLogin();
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.userId = "";
              this.userName = "";
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 1
            ) {
              console.log("isLogin Flase type 1");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.userId = "";
              this.userName = "";
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.login();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getUser() {
      userDetail(this.userId).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        localStorage.setItem("userId", res.data.gptsUserId);
        this.$store.state.user = res.data;
        this.$store.state.userId = res.data.gptsUserId;
        this.userId = res.data.gptsUserId;
        this.userName = this.userInfo.gptsUserName;
        this.avator=this.baseURL+this.userInfo.avatar
      });
    },
    outLogin() {
      // localStorage.removeItem("userId");
      // window.location.reload();
      logOut()
          .then((res) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // deleteUserAgent(localStorage.getItem("murmur"))
            let data = {
              userAgent: localStorage.getItem("murmur"),
            };
            this.$axios
                .post(
                    "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
                    data
                )
                .then(() => {
                  localStorage.removeItem("murmur");
                  window.location.href = res.data;
                });
          })
          .catch((error) => {
            console.error("退出登录失败:", error);
            this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
            // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
          });
    },
    login() {
      // this.$router.push("/login")
      // let href = "http://www.ogdri.com//";
      // let href = window.location.href.split("&code")[0];
      //
      // console.log(href)
      // getAuthorize(href).then((res) => {
      //   window.location.href = res.data.url;
      // });
    },
    back() {
      window.open("http://cn.ogdri.com/", "_blank");
    },
    English(){
      const routeName = this.$route.name;
      console.log(routeName)
      if(routeName==="Home"){
        window.open("https://www.ogdri.com/", "_blank");
      }
      else{
        window.open("https://www.ogdri.com/"+routeName, "_blank");
      }

    },
    gpts() {
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    show() {

      this.$emit("showDialog");

    },
    hide() {
      this.dialogconsult = false;
    },
  },
};
</script>


<style lang="scss" scoped type="text/scss">
.avator {
  border: 2px solid #fff;
}

.userName {
  color: white;
  font-size: 1vw;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layer1 {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5vw;

  .leftLayer {
    cursor: pointer;
    width: 12.2790294627383vw;
    height: 6.56242274412855vh;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicitynet {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      //background: rgba(255, 255, 255,0.9);
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
      display: flex;
      .net{
        background-image: url("../assets/net.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
      }
    }
    .communicitynet:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    .communicityGPT{
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2f6eff;
      background:white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden;
    }
    .communicityGPT:hover {
      background:rgba(255, 255, 255, 0.5);
      color: white;
    }
    .communicity {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    .communicity:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    .loginUser {
      //z-index: 8;
      //border: white 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2f6eff;
      line-height: 3.33745364647713vh;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      background: white;
      border: white 1px solid;
    }

    .login:hover {
      color: white;
      background: rgba(255, 255, 255, 0.5);
      border: white 1px solid;
    }
  }
}

.layerModel {
  display: flex;
  justify-content: space-between;
  .userName {
    color: white;
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    margin-left: 10px;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .leftLayer {
    cursor: pointer;
    width: 120px;
    height: 30px;
    margin-top: -5px;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    margin-top: -0.89443757725587vh;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicity {
      //z-index: 8;
      cursor: pointer;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    .communicity:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    .communicitynet {
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 5px 4.6875vw;
      height: 35px;
      .net{
        background-image: url("../assets/netModel.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 14px;
        height: 14px;
      }
    }

    .communicitynet:hover {
      color: white;
      background: rgba(255, 255, 255, 0.5);
      border: white 1px solid;
    }

    .loginUser {
      //z-index: 8;
      //border: white 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2f6eff;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 5px 4.6875vw;
      height: 35px;
      background: white;
      border: white 1px solid;
    }

    .login:hover {
      color: white;
      background: rgba(255, 255, 255, 0.5);
      border: white 1px solid;
    }
  }
}
.layer2 {
  .title1 {
    margin:0 auto;
    width: fit-content;
    height:23px;
    font-size: 23px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 500;
    color: #111111;
    line-height: 8.23733003708282vh;
  }
  .title2 {
    margin:35px auto 0 auto;
    width: fit-content;
    height:17px;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #111111;
    line-height:24px;
  }
  .title3 {
    display: flex;
    text-align: center;
    margin: 20px auto 0 auto;
    width: fit-content;
    height: 35px;
    font-size: 8px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: white;
    line-height:22px;
  }
}
.layer3 {
  position: absolute;
  display: flex;
  bottom:15px;
  opacity: 1;
  width: 90%;
  justify-content: center;
  .communicity {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #2f6eff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }
  .communicityGpt {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color:white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    background-color:#2f6eff ;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

}
</style>
