// import request from '@/utils/request'
// // 获取用户信息   /thirdPlatform/home/user/info
// export function userInfo() {
//   return request({
//     url: '/thirdPlatform/home/user/info',
//     method: 'get',
//   })
// }
//
// // 获取手机验证码 /system/user/phoneCode/{phone}
// export function phoneCode(data) {
//   return request({
//     url: `/system/job_user/phoneCode`,
//     method: 'post',
//     data: data,
//   })
// }
//
// // 获取邮箱验证码 /system/user/emailCode/{phone}
// export function emailCode(data) {
//   return request({
//     url: `/system/job_user/emailCode/`,
//     method: 'get',
//     params: data,
//   })
// }
//
//
// // 获取协议 /system/agreement/detail/{id}   1  隐私协议   2   注册协议
// export function agreement(id) {
//   return request({
//     url: `/system/agreement/detail/${id}`,
//     method: 'get',
//   })
// }
//
// // 手机注册 /system/user/phoneRegister
// export function phoneRegister(data) {
//   return request({
//     url: `/system/job_user/phoneRegister`,
//     method: 'post',
//     data: data,
//   })
// }
//
// // 邮箱注册 /system/user/emailRegister
// export function emailRegister(data) {
//   return request({
//     url: `/system/job_user/emailRegister`,
//     method: 'post',
//     data: data,
//   })
// }
//
// // 账号登录  /system/user/login
// export function userLogin(data) {
//   return request({
//     url: `/system/system/job_user/login      `,
//     method: 'post',
//     data: data,
//   })
// }
//
// // 短信登录  /system/user/phoneLogin
// export function phoneLogin(data) {
//   return request({
//     url: `/system/job_user/phoneLogin`,
//     method: 'post',
//     data: data,
//   })
// }
//
// // 忘记密码  /system/user/forgetPassword
// export function forgetPassword(data) {
//   return request({
//     url: `/system/user/forgetPassword`,
//     method: 'post',
//     data: data,
//   })
// }
// // 退出登录  /system/oauth/logOut
// export function logOut(data) {
//   return request({
//     url: `/system/oauth/logOut`,
//     method: 'get',
//     params: {
//       redirect_uri: data
//     }
//   })
// }
// 修改个人密码 - 竹云  auth/oauth/pwdChange
export function pwdChange(data) {
  return request({
    url: `system/oauth/pwdChange`,
    method: 'post',
    data: data
  })
}
//
// 发送短信验证码 - 竹云  /auth/oauth/smsSend
export function smsSend(data) {
  return request({
    url: `/system/oauth/smsSend`,
    method: 'post',
    data: data
  })
}
// 手机号绑定  - 竹云  /api/user/bindPhone
export function bindPhone(data) {
  return request({
    url: `/system/oauth/upPhone`,
    method: 'post',
    data: data
  })
}
// 判断用户是否绑定微信 /auth/oauth/social
export function social(data) {
  return request({
    url: `/system/oauth/social`,
    method: 'get',
    params: data
  })
}

// 竹云社交账号解绑  /auth/oauth/unbindAccount
export function unbindAccount(data) {
  return request({
    url: `/system/oauth/unbindAccount`,
    method: 'get',
    params: data
  })
}
// export function upEmail(data) {
//   return request({
//     url: `/auth/oauth/upEmail`,
//     method: 'post',
//     data: data,
//   })
// }
// // 修改手机号 /system/user/upPhone
// export function upPhone(data) {
//   return request({
//     url: `/auth/oauth/upPhone`,
//     method: 'post',
//     data: data,
//   })
// }
//
// 微信绑定  /system/user/wxBind
export function wxBind(data) {
  return request({
    url: `/system/oauth/wxBind`,
    method: 'get',
    params: {
      code: data
    }
  })
}
//
// // 获取用户详细信息  info/gpts_user/{UserId}
// export function userDetail(UserId) {
//   return request({
//     url: `auth/oauth/${UserId}`,
//     method: 'get',
//   })
// }
//
// // 修改用户基本信息  /system/user/update
// export function userUpdate(data) {
//   return request({
//     url: `/system/job_user`,
//     method: 'put',
//     data: data,
//   })
// }
// export function jobUpdate(data) {
//   return request({
//     url: `/system/expectations`,
//     method: 'put',
//     data: data,
//   })
// }
//
//
// export function workUpdate(data) {
//   return request({
//     url: `/system/work_experience`,
//     method: 'put',
//     data: data,
//   })
// }
//
// export function eduUpdate(data) {
//   return request({
//     url: `/system/edu_experience`,
//     method: 'put',
//     data: data,
//   })
// }
//
// export function proUpdate(data) {
//   return request({
//     url: `/system/project_experience`,
//     method: 'put',
//     data: data,
//   })
// }
// // 上传单个文件  /common/upload
// export function UploadData(data) {
//   return request({
//     url: `/common/upload`,
//     method: 'post',
//     data: data,
//   })
// }
//
//
//
//
// // 取消我的订阅  /system/subscribe/delete/{id}
// export function deleteReport(id) {
//   return request({
//     url: `/system/subscribe/delete/${id}`,
//     method: 'get',
//   })
// }
//
// // 获取系统消息条数  /system/notice/count/{userId}
// export function noticeCount(userId) {
//   return request({
//     url: `/system/notice/count/${userId}`,
//     method: 'get',
//   })
// }
//
// // 获取系统消息列表  /system/notice/list/{userId}
// export function noticeList(data) {
//   return request({
//     url: `/system/notice/list`,
//     method: 'get',
//     params: data
//   })
// }
//
// // 获取系统通知详情   /system/notice/detail/{noticeId}
// export function noticeDetail(id) {
//   return request({
//     url: `/system/notice/detail/${id}`,
//     method: 'get',
//   })
// }
//
// // 修改通知公告已读状态   /system/notice/upStatus/{id}
// export function noticeUpStatus(id) {
//   return request({
//     url: `/system/notice/upStatus/${id}`,
//     method: 'get',
//   })
// }
//
//
// // 获取微信code  /system/user/getWxCode
// export function getWxCode(data) {
//   return request({
//     url: `/auth/oauth/getWxCode`,
//     method: 'get',
//     params: {
//       redirect_uri: data
//     }
//   })
// }
//
// // 判断用户是否绑定 /system/user/isBind
// export function isBind(data) {
//   return request({
//     url: `/auth/user/isBind`,
//     method: 'POST',
//     data: {
//       wxCode: data
//     }
//   })
// }
//
// // 微信登录  /system/user/wxLogin
// export function wxLogin(data) {
//   return request({
//     url: `/system/user/wxLogin`,
//     method: 'POST',
//     data: data
//   })
// }
//
//
// // 微信解绑  /system/user/unBind
// export function unBind(data) {
//   return request({
//     url: `/system/user/unBind`,
//     method: 'POST',
//     data: data
//   })
// }
//
//
// // 登录获取授权码url /system/oauth/getAuthorize
// export function getAuthorize(data) {
//   return request({
//     url: `/auth/oauth/getAuthorize`,
//     method: 'get',
//     params: {
//       redirect_uri: data
//     }
//   })
// }
//
// // 获取用户信息  /system/oauth/getUser
// export function getUser(data) {
//   return request({
//     url: `/auth/oauth/getUser`,
//     method: 'get',
//     params: {
//       code: data.code,
//       redirect_uri: data.url
//     }
//   })
// }
//
// // 检测token有效性  /system/oauth/introspect
// export function introspect(data) {
//   return request({
//     url: `/auth/oauth/introspect`,
//     method: 'get',
//     params: {
//       access_token: data
//     }
//   })
// }
//
//
// //根据浏览器唯一标识获取浏览器用户登录状态 /system/oauth/getLoginStatus
// export function getLoginStatus(data) {
//   return request({
//     url: `/auth/oauth/getLoginStatus`,
//     method: 'post',
//     data: data
//   })
// }
//
// //用户登录插入浏览器唯一标识 /system/oauth/insertUserAgent
// export function insertUserAgent(data) {
//   return request({
//     url: `/auth/oauth/insertUserAgent`,
//     method: 'post',
//     data: data
//   })
// }
//
// // 删除唯一标识  /system/oauth/deleteUserAgent
// export function deleteUserAgent(data) {
//   return request({
//     url: `/auth/oauth/deleteUserAgent`,
//     method: 'post',
//     data: {
//       userAgent: data
//     }
//   })
// }
// 获取图形验证码 /auth/oauth/captcha
export function captcha() {
  return request({
    url: `/system/oauth/captcha`,
    method: 'get'
  })
}
// 校验图形验证码  /auth/oauth/captchaVerify
export function captchaVerify(data) {
  return request({
    url: `/system/oauth/captchaVerify`,
    method: 'post',
    data: data
  })
}
// 获取国际区号  /auth/oauth/countryC
// 发送邮箱验证码 /auth/oauth/emailSend
export function emailSend(data) {
  return request({
    url: `/system/oauth/emailSend`,
    method: 'post',
    data: data
  })
}
import request from '@/utils/request'
// 获取用户信息   /thirdPlatform/home/user/info
export function userInfo() {
  return request({
    url: '/thirdPlatform/home/user/info',
    method: 'get',
  })
}



// 退出登录  /system/oauth/logOut
export function logOut(data) {
  return request({
    url: `/system/oauth/logOut`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 获取用户详细信息  info/gpts_user/{UserId}
export function userDetail(UserId) {
  return request({
    url: `system/oauth/${UserId}`,
    method: 'get',
  })
}





// 上传单个文件  /common/upload
export function UploadData(data) {
  return request({
    url: `/common/upload`,
    method: 'post',
    data: data,
  })
}

// 查询是否设置密码 auth/oauth/checkExist
export function checkExist(data) {
  return request({
    url: `/system/oauth/checkExist`,
    method: 'get',
    params: data
  })
}

// 修改邮箱 /system/user/upEmail
export function upEmail(data) {
  return request({
    url: `/system/oauth/upEmail`,
    method: 'post',
    data: data,
  })
}




// 获取微信code  /system/user/getWxCode
export function getWxCode(data) {
  return request({
    url: `/system/user/getWxCode`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 判断用户是否绑定 /system/user/isBind
export function isBind(data) {
  return request({
    url: `/system/user/isBind`,
    method: 'POST',
    data: {
      wxCode: data
    }
  })
}

// 微信登录  /system/user/wxLogin
export function wxLogin(data) {
  return request({
    url: `/system/user/wxLogin`,
    method: 'POST',
    data: data
  })
}
//
// // 微信绑定  /system/user/wxBind
// export function wxBind(data) {
//   return request({
//     url: `/system/user/wxBind`,
//     method: 'get',
//     params: {
//       code: data
//     }
//   })
// }
// 微信解绑  /system/user/unBind

export function unBind(data) {
  return request({
    url: `/system/user/unBind`,
    method: 'POST',
    data: data
  })
}


// 登录获取授权码url /system/oauth/getAuthorize
export function getAuthorize(data) {
  return request({
    url: `/system/oauth/getAuthorize`,
    method: 'get',
    params: {
      redirect_uri: data
    }
  })
}

// 获取用户信息  /system/oauth/getUser
export function getUser(data) {
  return request({
    url: `/system/oauth/getUser`,
    method: 'get',
    params: {
      code: data.code,
      redirect_uri: data.url
    }
  })
}

// 检测token有效性  /system/oauth/introspect
export function introspect(data) {
  return request({
    url: `/system/oauth/introspect`,
    method: 'get',
    params: {
      access_token: data
    }
  })
}


//根据浏览器唯一标识获取浏览器用户登录状态 /system/oauth/getLoginStatus
export function getLoginStatus(data) {
  return request({
    url: `/system/oauth/getLoginStatus`,
    method: 'post',
    data: data
  })
}

//用户登录插入浏览器唯一标识 /system/oauth/insertUserAgent
export function insertUserAgent(data) {
  return request({
    url: `/system/oauth/insertUserAgent`,
    method: 'post',
    data: data
  })
}

// 删除唯一标识  /system/oauth/deleteUserAgent
export function deleteUserAgent(data) {
  return request({
    url: `/system/oauth/deleteUserAgent`,
    method: 'post',
    data: {
      userAgent: data
    }
  })
}
